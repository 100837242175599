export enum DeviceType {
  PRINTER_DEVICE = 'PrinterDevice',
  PC_DEVICE = 'PCDevice'
}

export enum DeviceTypeParam {
  PRINTER = 'PRINTER',
  NOTEBOOK = 'NOTEBOOK',
  CHROMEBOOK = 'CHROMEBOOK',
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
  PC = 'PC',
  MONITOR = 'MONITOR',
  SCANNER = 'SCANNER',
  CALCULATOR = 'CALCULATOR',
  ACCESSORY = 'ACCESSORY',
  KEYBOARD = 'KEYBOARD',
  MOUSE = 'MOUSE',
  PEN = 'PEN',
  KBM = 'KBM',
  DOCKING_STATION = 'DOCKING_STATION',
  HEADSET = 'HEADSET',
  PHONE = 'PHONE',
  SOFTWARE = 'SOFTWARE',
  VIDEO_CONFERENCE = 'VIDEO_CONFERENCE',
  HYPER_X = 'HYPER_X',
  UNKNOWN = 'UNKNOWN'
}

export const enum ScreenName {
  DEVICES = 'Devices',
  DEVICE_DETAILS = 'DeviceDetails'
}
