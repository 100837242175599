import { useEffect, useState } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { mountDevicePageUrl, mountMethoneCacheApiUrl } from './utils/mountUrls';
import { CommonsNorthboundAPIsType } from 'src/contexts/dependencyManager/utils/getCommonsNorthboundAPIs/types';
import { URLS } from 'src/utils/urls';

interface IUseCacheApi {
  serialNumber: string;
  productNumber: string;
  useMethoneCacheApiDeviceSupport: boolean;
}

interface IUseCacheApiState {
  deviceSupportUrl: string;
  isLoading: boolean;
  error: Error | null;
}

const fetchDeviceSupportUrl = async (
  serialNumber: string,
  productNumber: string,
  northboundAPIs: CommonsNorthboundAPIsType
): Promise<string> => {
  const userToken = await northboundAPIs.v1.authProvider.getAccessToken();
  const methoneCacheApiUrl = mountMethoneCacheApiUrl();

  const response = await fetch(methoneCacheApiUrl, {
    method: 'POST',
    body: JSON.stringify({
      serialNumber: serialNumber,
      productNumber: productNumber
    }),
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  return mountDevicePageUrl({
    token: data.CacheToken || '',
    northboundAPIs: northboundAPIs
  });
};

const useCacheApi = ({
  serialNumber,
  productNumber,
  useMethoneCacheApiDeviceSupport = false
}: IUseCacheApi): IUseCacheApiState => {
  const { northboundAPIs } = useDependencyManagerContext();
  const [deviceSupportUrl, setDeviceSupportUrl] = useState<string>(
    !useMethoneCacheApiDeviceSupport
      ? URLS.DeviceSupport
      : mountDevicePageUrl({
          northboundAPIs: northboundAPIs
        })
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!useMethoneCacheApiDeviceSupport || !serialNumber || !productNumber) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const url = await fetchDeviceSupportUrl(
          serialNumber,
          productNumber,
          northboundAPIs
        );
        setDeviceSupportUrl(url);
      } catch (error) {
        setError(error as Error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [
    northboundAPIs,
    productNumber,
    serialNumber,
    useMethoneCacheApiDeviceSupport
  ]);

  return { deviceSupportUrl, isLoading, error };
};

export default useCacheApi;
