import {
  Card,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { IconHeadset } from '@veneer/core';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import useCacheApi from 'src/hooks/useCacheApi/useCacheApi';
import { Device } from 'src/types/IDevices';
import { GetDeviceSupport } from './analytics';

interface MoreOptionsProps {
  deviceDetails: string;
  device: Device;
  useMethoneCacheApiDeviceSupport: boolean;
}

const MoreOptions = ({
  deviceDetails,
  device,
  useMethoneCacheApiDeviceSupport
}: MoreOptionsProps) => {
  const { translate } = useDependencyManagerContext();

  const { deviceSupportUrl } = useCacheApi({
    serialNumber: device?.identity?.serialNumber,
    productNumber: device?.identity?.makeAndModel?.number,
    useMethoneCacheApiDeviceSupport: useMethoneCacheApiDeviceSupport
  });
  const analyticsEvent = GetDeviceSupport(deviceDetails, deviceSupportUrl);

  return (
    <Card
      title={{
        content: translate('common.moreOptions', 'More options') as string
      }}
      rows={[
        {
          index: 0,
          content: translate(
            'devices.getDeviceSupport',
            'Get device support'
          ) as string,
          type: ROW_TYPE.EXTERNAL,
          icon: <IconHeadset />,
          link: {
            url: deviceSupportUrl,
            dataTestId: 'device-support-link'
          },
          analyticsEvent: analyticsEvent
        }
      ]}
    />
  );
};

export default MoreOptions;
