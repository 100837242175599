const GET_DEVICE = `
  query Query($deviceId: String!) {
    devices(deviceId: $deviceId) {
      items {
        deviceId
        identity {
          friendlyName
          serialNumber
          makeAndModel {
            name
            number
          }
        }
        images {
          url
        }
        type
      }
      totalCount
    }
  }
`;

export default GET_DEVICE;
