import {
  ACTIONS,
  activity,
  AnalyticsEventWithControlName,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { screenPathDevice } from 'src/utils/constants';
import { ScreenName } from 'src/utils/enums';

export const GetDeviceSupport = (
  deviceDetails: string,
  url: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath: screenPathDevice,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'GetDeviceSupportButton',
    controlDetail: url,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};
