import { publishEvent } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useEffect } from 'react';
import { Subtitle } from 'src/components/Subtitle';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { Label, Link, Row, SectionCard } from 'src/styles/styles';
import { Device } from 'src/types/IDevices';
import { DeviceType } from 'src/utils/enums';
import getDeviceImage from 'src/utils/getDeviceImage';
import { URLS, getHPSmartPrinterOverviewUrl } from 'src/utils/urls';
import {
  ControlTileClicked,
  DeviceOverview,
  PrinterOverview,
  ViewWarrantyStatus
} from './analytics';
import { DeviceImage, FormDeviceName } from './styles';

interface DeviceDetailsProps {
  device: Device;
}

const DeviceDetails = ({ device }: DeviceDetailsProps) => {
  const { translate } = useDependencyManagerContext();
  const deviceDetailsAnalytics = `deviceType=${device?.type?.toLowerCase()}&productNumber=${device?.identity?.makeAndModel?.number}`;

  useEffect(() => {
    if (device) {
      publishEvent(ControlTileClicked(deviceDetailsAnalytics));
    }
  }, [device, deviceDetailsAnalytics]);

  return (
    <SectionCard
      data-testid="DeviceDetails"
      appearance="outlined"
      content={
        <>
          <Row>
            <DeviceImage>
              {getDeviceImage(device?.images, device?.__typename)}
            </DeviceImage>
            <FormDeviceName>
              <Subtitle
                {...(device?.identity?.friendlyName && {
                  dataComponent: 'DevicesDeviceDetailsFriendlyNameValue'
                })}
              >
                {device?.identity?.friendlyName ||
                  device?.identity?.makeAndModel?.name}
              </Subtitle>
              {device?.__typename === DeviceType.PRINTER_DEVICE ? (
                <Link
                  href={getHPSmartPrinterOverviewUrl()}
                  target="_blank"
                  rel="noreferrer"
                  className="caption"
                  onClick={() =>
                    publishEvent(PrinterOverview(deviceDetailsAnalytics))
                  }
                >
                  {`${translate('devices.printerOverview', 'Printer overview')}`}
                </Link>
              ) : (
                <Link
                  href={URLS.SupportDashboard}
                  target="_blank"
                  rel="noreferrer"
                  className="caption"
                  onClick={() =>
                    publishEvent(DeviceOverview(deviceDetailsAnalytics))
                  }
                >
                  {`${translate('devices.deviceOverview', 'Device overview')}`}
                </Link>
              )}
            </FormDeviceName>
          </Row>
          <Row>
            <Label>{`${translate('devices.productName', 'Product name')}`}</Label>
            <p className="body">{device?.identity?.makeAndModel?.name}</p>
          </Row>
          {device?.identity?.makeAndModel?.number && (
            <Row>
              <Label>
                {`${translate('devices.productNumber', 'Product number')}`}
              </Label>
              <p className="body"> {device.identity.makeAndModel.number}</p>
            </Row>
          )}
          <Row>
            <Label>{`${translate('devices.serialNumber', 'Serial number')}`}</Label>
            <p
              data-component="DevicesDeviceDetailsSerialNumberValue"
              className="body"
            >
              {' '}
              {device?.identity?.serialNumber}
            </p>
          </Row>
          <Row>
            <Label>{`${translate('devices.warranty', 'Warranty')}`}</Label>
            <Link
              href={URLS.SupportDashboard}
              target="_blank"
              rel="noreferrer"
              className="caption"
              onClick={() =>
                publishEvent(ViewWarrantyStatus(deviceDetailsAnalytics))
              }
            >
              {`${translate('devices.viewStatus', 'View status')}`}
            </Link>
          </Row>
        </>
      }
    />
  );
};

export default DeviceDetails;
