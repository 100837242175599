import {
  ContainerMFE,
  useGetFeatureFlags,
  FeatureFlagKeyValues
} from '@hpx-core-experiences/react-my-account-commons/dist';
import React, { useMemo } from 'react';
import {
  DependencyManagerProvider,
  useDependencyManagerContext
} from 'src/contexts/dependencyManager';
import Page from 'src/router';
import '../styles/global.scss';
import * as T from './types';

export interface FeatureFlagProps {
  isLoading: boolean;
  originalFeedbackExperience: boolean;
  useMethoneCacheApiDeviceSupport: boolean;
}

export interface DeviceDetailingFeatureFlagProps extends FeatureFlagProps {}

export interface DeviceFeatureFlagProps
  extends Omit<FeatureFlagProps, 'useMethoneCacheApiDeviceSupport'> {}

const RoutesComponent = (featureFlagProps: FeatureFlagProps) => {
  return (
    <ContainerMFE data-testid="device-page">
      <Page {...featureFlagProps} />
    </ContainerMFE>
  );
};

const MemoizedRoutesComponent = React.memo(RoutesComponent);

const MainComponent: React.FC<T.AppPropsType> = () => {
  const { northboundAPIs } = useDependencyManagerContext();

  const client = useMemo(
    () => northboundAPIs.v1.graphql.getClient(),
    [northboundAPIs.v1.graphql]
  );

  const GraphQLProvider = useMemo(
    () => northboundAPIs.v1.graphql.reactTools.createGraphQLProvider(React),
    [northboundAPIs.v1.graphql.reactTools]
  );

  const featureFlagClient = useMemo(
    () => northboundAPIs?.v1?.featureFlags?.getClient,
    [northboundAPIs?.v1?.featureFlags?.getClient]
  );

  const featureFlagProps = useGetFeatureFlags({
    getClient: featureFlagClient,
    keys: [
      FeatureFlagKeyValues.originalFeedbackExperience,
      FeatureFlagKeyValues.useMethoneCacheApiDeviceSupport
    ]
  });

  return (
    <GraphQLProvider client={client}>
      <MemoizedRoutesComponent {...featureFlagProps} />
    </GraphQLProvider>
  );
};

const MemoizedMainComponent = React.memo(MainComponent);

const App: React.FC<T.AppPropsType> = () => {
  return (
    <DependencyManagerProvider>
      <MemoizedMainComponent />
    </DependencyManagerProvider>
  );
};

export default App;
