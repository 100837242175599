import React, { memo } from 'react';
import { FeatureFlagProps } from 'src/App';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import DeviceDetailing from 'src/pages/DeviceDetailing';
import Devices from 'src/pages/Devices';

const Router = (props: FeatureFlagProps) => {
  const { northboundAPIs } = useDependencyManagerContext();
  const { pathname } = northboundAPIs.v1.navigation.location || {};

  const pageName = pathname?.split('/')?.pop()?.toLowerCase();
  const devices = 'devices';

  const paths = pathname?.split('/').filter((item) => item != '') || [];

  if (paths.length > 1 && paths.includes(devices) && pageName !== devices) {
    return <DeviceDetailing {...props} />;
  }

  return (
    <Devices
      isLoading={props.isLoading}
      originalFeedbackExperience={props.originalFeedbackExperience}
    />
  );
};

export default memo(Router);
