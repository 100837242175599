import { Stack } from 'src/types/shell';
import { getStack } from 'src/utils/getStack';
import {
  STAGE_DEVICE_API_URL,
  PROD_DEVICE_API_URL,
  STAGE_METHONE_CACHE_API_URL,
  PROD_METHONE_CACHE_API_URL
} from '../constants/apiUrls';
import { CommonsNorthboundAPIsType } from 'src/contexts/dependencyManager/utils/getCommonsNorthboundAPIs/types';

interface IMountDevicePageUrl {
  token?: string;
  northboundAPIs: CommonsNorthboundAPIsType;
}

export const mountDevicePageUrl = ({
  token = '',
  northboundAPIs
}: IMountDevicePageUrl): string => {
  const stack = getStack();
  const cc = northboundAPIs.v1.localization.country || '';
  const lc = northboundAPIs.v1.localization.language || '';

  const urlTemplate =
    stack === Stack.stage ? STAGE_DEVICE_API_URL : PROD_DEVICE_API_URL;

  return urlTemplate
    .replace('TOKEN', token)
    .replace('CC', cc)
    .replace('LC', lc);
};

export const mountMethoneCacheApiUrl = (): string => {
  const stack = getStack();
  return stack === Stack.stage
    ? STAGE_METHONE_CACHE_API_URL
    : PROD_METHONE_CACHE_API_URL;
};
